import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});


function prepareEntries(json) {
  var date = new Date();
  var day = date.getDay();
  const opendays_fieldmap=["open_sun","open_mon","open_tue","open_wed","open_thu","open_fri","open_sat"];
  const rows=json.map((entry,index) => {
    entry.open_today=entry[opendays_fieldmap[day]] || {opens: "closed", closes: " "};
    console.log(entry);
    return entry;
    
  });
  return rows;
}


export default function SimpleTable() {
  const classes = useStyles();
  const [rows, updateRows] = React.useState([]);

  React.useEffect(function effectFunction() {
    async function fetchLokale() {
      const response = await fetch('https://api.hamburg.goobubble.com');
      const json = await response.json();
      var new_rows=prepareEntries(json);
      //console.log(new_rows);
      updateRows(new_rows);
    }
    fetchLokale();
  }, [] );

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Lokal</TableCell>
            <TableCell align="left">openening times today:</TableCell>
            <TableCell align="right">takeout</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.Name}
              </TableCell>
              <TableCell align="left">
                {row.open_today.opens} - {row.open_today.closes}
              </TableCell>
              <TableCell align="right">
                {row.takeout}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}